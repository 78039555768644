import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaHeart } from 'react-icons/fa';

import style from '../style/landing_ui.module.css';
import logo from '../assets/LaunchScreen@2x.png';

import Mailchimp from '../tools/mailChimp';

const NoPageFound = () => {
	return (
		<div>
			<Container fluid className={style.background}>
				<Row className={style.textContainers}>
					<Col />
					<Col>
						<img src={logo} alt="Grub Truck Logo" className={style.logoImg} />
						<br />

						<h1>404</h1>
						<p>Sorry, page not found</p>
						<br />
						<p>
							Copyright ©2021{' '}
							<a target="blank" href="https://webdmg.com/" style={{ color: 'white' }}>
								WEBDMG
							</a>
							<br />
							Developed by{' '}
							<a target="blank" href="https://webdmg.com/" style={{ color: 'white' }}>
								<FaHeart style={{ color: 'red' }} /> WEBDMG
							</a>
						</p>
					</Col>
					<Col />
				</Row>

				{/* Mailchimp pop up form link */}
				<Mailchimp />
				<Row />
			</Container>
		</div>
	);
};

export default NoPageFound;
